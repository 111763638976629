export const environment = {
	production: true,
	appInsightsInstrumentationKey: '704f39f0-230f-4851-88eb-22e2c1e3a9b4',
	name: "Phase 3 UAT",
	headerColor: "purple",
	dateFormat: "longDate",
	hostWebUrl: "/sites/Teletraan/OpenSIMS_UAT/",
	sharepointSiteId: 'ontariogov.sharepoint.com,f0767913-2548-4a1f-8fab-2e4005b7b82a,81e86aa8-0810-4d6f-90cf-4bdfbf397c05',
	sharepointSiteBaseUrl: 'https://ontariogov.sharepoint.com/',
	simsSite: "sites/Teletraan/OpenSIMS_UAT/",
	lowerSimsSite: "sites/Teletraan/OpenSIMS_IST/",
	signup: {
		api: "https://ws.stage.opensims-api.tcu.gov.on.ca/api/Signup?code=U0KI/kwn8UqCkzDD23JRjPJZ/8rBiXKe6U37G5Uqs7P7kDiRLDRGjw==",
		institutions: "https://ws.stage.opensims-api.tcu.gov.on.ca/api/institutions?code=U0KI/kwn8UqCkzDD23JRjPJZ/8rBiXKe6U37G5Uqs7P7kDiRLDRGjw==&origin=uat",
	},
	powerBiUrl: "https://app.powerbi.com/",
	auth:
	{
		clientId: 'febe5133-8f4c-493c-a5ed-7851c9ea22a5',
		authority: 'https://login.microsoftonline.com/cddc1229-ac2a-4b97-b78a-0e5cacb5865c/',
		redirectUri: 'https://stage.opensims.tcu.gov.on.ca',
		apiScope: 'api://3e4f15a7-30eb-4cd6-9647-2ad456fec9fc/DataFile.Upload'
	},
	reCaptchaSiteKey: '6LdhcAEaAAAAAB6acamsdEQpxtZiMvMG_YTIX4MZ',
	userGuideEnUrl: "https://ontariogov.sharepoint.com/sites/Teletraan/OpenSIMS_UAT/DataFiles/User%20Guide.pdf",
	userGuideFrUrl: "https://ontariogov.sharepoint.com/sites/Teletraan/OpenSIMS_UAT/DataFiles/Guide%20de%20l'utilisateur.pdf",
	siteAnalyticsReportUrl: "https://app.powerbi.com/reportEmbed?reportId=9a11e115-e4fa-4e94-b334-6512104e88d9",
	dataFilesManagement: {
		hasUatApprovalWorkflow: true,
		hasProdApprovalWorkflow: false,
		prodUrl: "https://www.opensims.tcu.gov.on.ca",
		adfContainerName: "adf-srcfiles",
		triggerStorageContainerName: "adf-trigger-files",
		azureAuditLogUrl: "https://ws.stage.opensims-api.tcu.gov.on.ca/api/AuditLog",
		azureEmailNotifyUrl: "https://ws.stage.opensims-api.tcu.gov.on.ca/api/EmailNotify",
		azureFunctionUrl: "https://ws.stage.opensims-api.tcu.gov.on.ca/api/UploadDataFile",
		azureRefreshUatFunctionUrl: "https://ws.stage.opensims-api.tcu.gov.on.ca/api/RefreshReport",
		azureTriggerUatFunctionUrl: "https://ws.stage.opensims-api.tcu.gov.on.ca/api/TriggerPipeline",
		azureTestEmailUrl: "https://ws.stage.opensims-api.tcu.gov.on.ca/api/TestEmailNotifications",
	}
};
